import classnames from "classnames";
import React, { FC } from "react";
import { FieldError } from "react-hook-form";
import useDigitInput from "react-digit-input";
import InputField from "components/base/input-field/InputField";
import styles from "./verification-code-input.module.sass";

interface IVerificationCodeInputProps {
    codeLength: number;
    error?: Partial<FieldError> | { type: string; message: string };
    disabled: boolean;
    variant: string;
    value: string;
    onChange: (val: string) => void;
}

const NUMBER_PATTERN = /^[0-9]$/;

const VerificationCodeInput: FC<IVerificationCodeInputProps> = ({
    codeLength,
    variant,
    error,
    disabled,
    value,
    onChange,
}) => {
    const codeDigits = useDigitInput({
        acceptedCharacters: NUMBER_PATTERN,
        length: codeLength,
        value,
        onChange,
    });

    return (
        <>
            {codeDigits.map((attributes, i) => {
                const { ref, ...rest } = attributes;

                return (
                    <InputField
                        key={i}
                        name={`${i + 1}`}
                        className={classnames(`code-input-${variant}`, {
                            [styles.wrongField]: error,
                        })}
                        disabled={disabled}
                        forwardRef={ref}
                        {...rest}
                    />
                );
            })}
        </>
    );
};

export default VerificationCodeInput;
