import { LocaleContext, useRuntimeConfig, useSiteConfig } from "@finbackoffice/site-core";
import {
    HeaderLayoutEnum,
    ISiteConfigLayouts,
    getLanguageNativeName,
} from "@finbackoffice/fe-core";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, useContext, useMemo } from "react";
import Translate from "components/base/translate/Translate";
import Dropdown, { DrowdownOptions } from "components/base/dropdown/Dropdown";
import styles from "./language-dropdown.module.sass";

const LanguageDropdown: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const { asPath, push, reload, pathname, query } = useRouter();
    const { locale } = useContext(LocaleContext);

    const onLocaleChangeHandler = async (val: any) => {
        await push({ pathname, query }, asPath, { locale: val, shallow: true });
        reload();
    };

    const getLocales: DrowdownOptions = useMemo(
        () =>
            COMMON_SITE_CONFIGS.activeLocales?.map((lang) => ({
                value: lang,
                label:
                    siteLayoutsConfig.header === HeaderLayoutEnum.V2
                        ? ""
                        : getLanguageNativeName(lang),
                svgSrc: `/common/languages/${lang}.svg`,
            })) ?? [],
        [COMMON_SITE_CONFIGS.activeLocales, siteLayoutsConfig.header],
    );

    const preventDefaultNavigation = (e: any) => {
        e.preventDefault();
    };

    return (
        <Dropdown
            styleClass={styles.dropdown}
            data={getLocales}
            selected={locale}
            onChange={onLocaleChangeHandler}
            testId="languages-dropdown"
            renderItem={(item) => (
                <Link
                    href={{
                        pathname: asPath,
                        query,
                    }}
                    shallow
                    locale={item.value}
                    onClick={preventDefaultNavigation}>
                    {typeof item.label === "string" && <Translate tid={item.label} />}
                </Link>
            )}
        />
    );
};

export default LanguageDropdown;
